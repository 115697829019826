import React from 'react';
import './index.css';

const PageFooter = () => {
    return (
        <div className='footer'>
            <p className='footer-text'> Copyright &copy; 2025 by Dylan | All right Reserved</p>
        </div>
    );
}

export default PageFooter;